import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import { TimelineMax, TimelineLite, TweenLite } from 'gsap/all'
import CSSPlugin from 'gsap/CSSPlugin'
import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
// import TransitionLink from 'gatsby-plugin-transition-link'
import ScrollMagicContext from '../components/ScrollMagic/ScrollMagicContext'
import ScrollMagic from '../components/ScrollMagic/ScrollMagic'
import { theme, media } from '../styles'
const { colors } = theme

const C = CSSPlugin // eslint-disable-line no-unused-vars
const { fonts, fontSizes } = theme

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FeaturedWorksOuter = styled.div`
  height: 100vh;
  width: 100vw;
  padding: 5%;
  overflow: hidden;

  ${media.phablet`
    overflow: scroll;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  `}
`

const FeaturedWorks = styled.div`
  max-height: 100%;
  display: flex;
  flex-wrap: nowrap;

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }

  ${media.thone`
    height: 80vh;
    padding: 35% 15%;
  `}
`

const ScrollRight = styled.div`
  position: absolute;
  z-index: 30;
  right: 0px;
  bottom: 50px;
  ${media.thone`
    top: 25%;
  `}
  
`

class GalleryPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      transformX: 0 // for transforming projects container
    }

    // refs to document objects
    this.scrollRight = null
    this.content = null
    this.featuredWorks = null

    // bind functions
    this.scrollFn = _.throttle(this.scrollFn.bind(this), 100)
    this.hideScroll = this.hideScroll.bind(this)
    this.tweenScroll = this.tweenScroll.bind(this)
  }

  componentDidMount() {
    // add event listener for scrolling
    window.addEventListener('wheel', this.scrollFn, false)
    window.addEventListener('mousewheel', this.scrollFn, false)
    window.addEventListener('DOMMouseScroll', this.scrollFn, false)
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.scrollFn, false)
    window.removeEventListener('mousewheel', this.scrollFn, false)
    window.removeEventListener('DOMMouseScroll', this.scrollFn, false)
  }

  scrollFn(event) { // for detecting and handling scroll events
    event.preventDefault()
    if (!event.type === 'wheel') return
    const delta = ((event.dventltaY || -event.wheelDelta || event.detail) >> 10) || 1
    let { transformX: shiftTransformX } = this.state
    const shiftDistance = 60

    if (delta === 1) { // scrolling down
      const featuredWorksClientRect = this.featuredWorks.getBoundingClientRect()
      const { width } = featuredWorksClientRect
      if (shiftTransformX > (-width)) {
        shiftTransformX -= shiftDistance
      }
    } else if (delta === -1) { // scrolling up
      if (shiftTransformX <= 0) {
        shiftTransformX += shiftDistance
      }
    }
    this.tweenScroll(shiftTransformX)
    this.setState({ transformX: shiftTransformX })
  }

  tweenScroll(transformX) {
    TweenLite.to(this.featuredWorks, 0.5, {
      x: transformX,
      ease: 'Linear'
    })
  }

  hideScroll() {
    this.animation = new TimelineMax({ paused: true })
      .set(this.scrollRight, { css: { animation: 'none' } })
      .to(this.scrollRight, 2, { x: '+=10', opacity: 0, delay: 0.5 })
      .play()
  }

  render() {
    const { data: { art: { edges } } } = this.props
    const featuredItems = edges

    return (
      <ScrollMagicContext
        location={`home`}
      >
        <Container ref={ref => { this.content = ref }}>
          <FeaturedWorksOuter>
            <FeaturedWorks ref={ref => { this.featuredWorks = ref }}>
              {featuredItems && featuredItems.map((item, index) => {
                const { node: { frontmatter } } = item
                return (
                  <AniLink
                    key={`featured_item_${index}`}
                    swipe="true" direction="up"
                    to={frontmatter.link}
                  >
                    <ScrollMagic
                      index={index}
                      component={`FEATURED_ITEM`}
                      frontmatter={frontmatter}
                      hideScroll={this.hideScroll}
                      shadowColor={colors.shadow}
                    />
                  </AniLink>
                )
              })}
            </FeaturedWorks>
          </FeaturedWorksOuter>
          <ScrollRight ref={ref => { this.scrollRight = ref }}>
            <ScrollMagic component={'SCROLL_RIGHT'} />
          </ScrollRight>
        </Container>
      </ScrollMagicContext>
    )
  }
}

export const query = graphql`
  query GalleryQuery {
    art: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/art/" } }
      sort: { fields: [frontmatter___id], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            name
            link
            image {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            textposition
            columnstart
            columnend
            rowstart
            rowend
          }
        }
      }
    }
  }
`

export default GalleryPage
